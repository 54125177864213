import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled from 'styled-components'
import { breakpoints, HideOverflow } from 'src/utils/styles/global-styles'
import { withTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Analytics from '../utils/Analytics'
import FAQs from '../components/FAQs'
import PressTestimonials from '../components/PressTestimonials'
import FrameTestimonials from '../components/FrameTestimonials'
import HomepageHero from '../components/HomepageHero/Index'
import CountDownTimer from '../components/CountDownTimer'
import HomepageProducts from '../components/HomepageProducts'
import FramePage from './frame'
import HomepageGuarantee from '../components/HomepageGuarantee'
import HomepageProductDetails from '../components/HomepageProductDetails'
import { IN_UK } from '../utils/constants'

const SHOW_UNIFIED_HOMEPAGE = process.env.GATSBY_SHOW_UNIFIED_HOMEPAGE
class IndexPage extends React.Component {
  componentDidMount() {
    Analytics.track('Viewed Homepage')
  }

  render() {
    const { t } = this.props
    if (!SHOW_UNIFIED_HOMEPAGE) {
      return (
        <>
          <SEO
            title={t('Skylight Frame')}
            description={t(
              'Skylight is a WiFi-enabled, touchscreen digital frame that lets you easily send pictures to your loved ones by email or mobile app.'
            )}
            path="/"
          />
          <FramePage />
        </>
      )
    }
    return (
      <>
        <SEO
          title={t('Skylight | Digital Picture Frame and Smart Calendar')}
          description={t(
            'Skylight changes the way you connect with loved ones. Skylight digital picture frames are the best way to share photos. Skylight Calendar is a smart touchscreen calendar for all your events and chores.'
          )}
          path="/"
        />
        <Helmet>
          <meta charset="UTF-8" />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
          />
        </Helmet>
        <HideOverflow>
          <HomepageHero />
          <HomepageProducts />
          <PressTestimonials page="frame" bgColor="#FFFEF9" bottom="80px;" top="0px 0px 20px;" />
          <HomepageGuarantee />
          <HomepageProductDetails />
          <FrameTestimonials />
          <FAQs page="unified" />
        </HideOverflow>
      </>
    )
  }
}

IndexPage.propTypes = {
  t: PropTypes.func,
}

export default withTranslation()(IndexPage)

export const DisplayOnMobile = styled.div`
  display: none;
  @media (max-width: ${breakpoints.s}px) {
    display: block;
  }
`

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
